import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { TrackingElement } from "./TrackingElement";

@customElement("survaq-scheduled-banner")
class SurvaqScheduledBanner extends TrackingElement {
  @property() from?: string;
  @property() to?: string;

  render() {
    if (!this.from && !this.to) return html``;

    const fromDateTime = new Date(this.from ?? "");
    const toDateTime = new Date(this.to ?? "");
    const currentDateTime = new Date();
    if (currentDateTime < fromDateTime || currentDateTime > toDateTime) return html``;

    return html`${this.renderTopMarker()}<slot></slot>${this.renderBottomMarker()}`;
  }
}
