import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { TrackingElement } from "./TrackingElement";

@customElement("survaq-event-pixel")
class SurvaqEventPixel extends TrackingElement {
  render() {
    return html`
      ${this.renderTopMarker()}
      <slot></slot>
      ${this.renderBottomMarker()}
    `;
  }
}
