import { LitElement, html, css } from "lit";
import { property, query } from "lit/decorators.js";
import { BaseLitElement } from "./BaseLitElement";

export class TrackingElement extends LitElement {
  @property({ type: String, reflect: true }) tracking: string = "";
  @property({ type: String, attribute: "tracking-category", reflect: true })
  trackingCategory?: string;
  @property({ type: String, attribute: "tracking-label", reflect: true }) trackingLabel?: string;

  @query(".top-marker") private topMarker?: HTMLElement;
  @query(".bottom-marker") private bottomMarker?: HTMLElement;

  private _observer: IntersectionObserver | null = null;
  private _eventSent: Set<string> = new Set();
  private _disposers: Map<string, () => void> = new Map();
  private _topInView: boolean = false;
  private _bottomInView: boolean = false;
  private _paused: boolean = false;

  constructor() {
    super();
  }

  disconnectedCallback() {
    this._dispose();
    super.disconnectedCallback();
  }

  firstUpdated() {
    if (this._paused) return;
    this._setEventHandler();
  }

  private _eventTrack(action: string) {
    if (this._eventSent.has(action)) return;

    if (import.meta.env.DEV) {
      console.log("eventTrack", action, {
        event_category: this.trackingCategory,
        event_label: this.trackingLabel,
      });
    } else {
      // @ts-ignore
      window.gtag?.("event", action, {
        event_category: this.trackingCategory,
        event_label: this.trackingLabel,
      });
    }

    this._eventSent.add(action);
  }

  private _dispose() {
    this._disposers.forEach((disposer) => disposer());
  }

  private _setEventHandler() {
    const targets = this.tracking.split(",").map((t) => t.trim());
    if (!this.tracking) return;

    if (targets.includes("click")) {
      this.addEventListener("click", this._handleClick);
      this._disposers.set("click", () => this.removeEventListener("click", this._handleClick));
    }

    if (targets.includes("view")) {
      this._observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // 要素の上部が画面内に入ったかどうか
            if (!this._topInView && entry.target === this.topMarker)
              this._topInView = entry.isIntersecting;
            // 要素の下部が画面内に入ったかどうか
            else if (!this._bottomInView && entry.target === this.bottomMarker)
              this._bottomInView = entry.isIntersecting;

            // 両方のマーカーが画面内に入った場合
            if (this._topInView && this._bottomInView) this._handleView();
          });
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 1,
        },
      );

      if (this.topMarker && this.bottomMarker) {
        this._observer.observe(this.topMarker);
        this._observer.observe(this.bottomMarker);
        this._disposers.set("view", () => {
          this._observer?.disconnect();
        });
      }
    }
  }

  private _handleClick() {
    this._eventTrack("click");
  }

  private _handleView() {
    this._eventTrack("view");
  }

  protected renderTopMarker() {
    return html`<div
      class="top-marker absolute left-0 right-0 top-0 h-[1px] overflow-hidden"
    ></div>`;
  }

  protected renderBottomMarker() {
    return html`<div
      class="bottom-marker absolute left-0 right-0 bottom-0 h-[1px] overflow-hidden"
    ></div>`;
  }

  protected pauseTracking() {
    this._paused = true;
    this._dispose();
  }

  protected resumeTracking() {
    this._paused = false;
    this._setEventHandler();
  }

  static styles = [
    ...BaseLitElement.styles,
    css`
      :host {
        position: relative;
        display: block;
      }
    `,
  ];
}
