import { css, html } from "lit";
import { Task } from "@lit/task";
import { customElement, property } from "lit/decorators.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { ProductDetailsRoute } from "../../routes/_apis/products";
import { BaseLitElement } from "./BaseLitElement";
import { hc } from "hono/client";
import { cacheFetch } from "../../libs/client/fetch";
import { Schedule } from "../../libs/makeSchedule";
import { CustomNotFoundError } from "../../libs/errors";

@customElement("survaq-delivery-schedule")
class SurvaqDeliverySchedule extends BaseLitElement {
  @property() productId?: string;
  @property({ type: Boolean }) delayedOnly: boolean = false;
  @property({ type: Boolean }) hideWhenNoDelay: boolean = false;

  private _productTask = new Task(this, {
    task: async ([productId, delayedOnly], { signal }) => {
      if (!productId) throw new Error("productIdを指定してください。");

      const baseUrl = new URL("https://api.survaq.com/products/");
      if (import.meta.env.DEV) {
        baseUrl.protocol = "http:";
        baseUrl.hostname = "localhost";
        baseUrl.port = "8000";
      }

      let retryCount = 0;
      while (retryCount++ < 3) {
        try {
          const client = hc<ProductDetailsRoute>(baseUrl.toString(), {
            init: { signal },
            fetch: cacheFetch,
          });
          const res = await client[":id"].$get({
            param: { id: productId },
          });

          if (res.status === 404) throw new CustomNotFoundError("商品が存在しません。");
          if (!res.ok) throw new Error(`想定しないエラーが発生しました。status: ${res.status}`);
          return res.json();
        } catch (e) {
          console.warn(e);
          if (e instanceof CustomNotFoundError) throw e;
          // 3秒後にリトライ
          await new Promise((r) => setTimeout(r, 3000));
        }
      }
      throw new Error("想定しないエラーが発生しました。");
    },
    args: () => [this.productId, this.delayedOnly] as const,
  });

  render() {
    return this._productTask.render({
      pending: () => html``,
      complete: (product) => {
        const skus = product.skus.reduce<
          Array<{
            code: string;
            name: string;
            schedule: Schedule;
            sortNumber: number;
            delaying: boolean;
          }>
        >((acc, sku) => {
          const delaying = !!sku.schedule && sku.schedule.numeric > 0;
          if (
            acc.find(({ code }) => code === sku.code) ||
            !sku.schedule ||
            (this.delayedOnly && !delaying)
          )
            return acc;
          return [
            ...acc,
            {
              code: sku.code,
              name: sku.displayName || sku.name,
              schedule: sku.schedule,
              sortNumber: sku.sortNumber,
              delaying,
            },
          ];
        }, []);

        const hasDelayed = skus.some(({ delaying }) => delaying);
        const allDelayed = skus.every(({ delaying }) => delaying);
        if (this.hideWhenNoDelay && !hasDelayed) return null;
        if (skus.length < 1) return null;
        return html`<div class="pb-4">
          ${allDelayed
            ? html`
                <p
                  class="text-slate-700 text-center text-sm font-bold block p-1 my-1 mx-0 border-y-4 border-double border-gray-400"
                >
                  <span class="inline-block">下記商品につきましては、</span>
                  <span class="inline-block">数日中の発送可能な在庫が完売のため</span>
                  <span class="inline-block">発送時期が異なります。</span>
                </p>
              `
            : null}
          <table class="w-full">
            <thead></thead>
            <tbody>
              ${skus.map(
                (sku) =>
                  html`<tr>
                    <th
                      class="bg-neutral-400 p-2 text-white text-xs border-y border-white min-w-38 sm:w-52 w-40"
                    >
                      <span>${unsafeHTML(sku.name)}</span>
                    </th>
                    <td
                      class="p-1 text-center font-bold text-slate-700 border-y border-neutral-400 pt-1 pb-2"
                    >
                      ${sku.delaying
                        ? html`<p class="text-2xl text-red-500 m-0">△</p>`
                        : html`<p class="text-2xl text-green-500 m-0">◯</p>`}
                      <p class="text-xs leading-none">
                        ${sku.delaying
                          ? `${sku.schedule.text.replace(/(\d{4}|年)/g, "")}発送予定`
                          : "残りわずか(最短翌日発送)"}
                      </p>
                    </td>
                  </tr>`,
              )}
            </tbody>
          </table>
          <p class="my-1 text-center text-xs text-slate-700">
            ◎：在庫あり｜◯：残りわずか｜△：入荷後発送｜×：完売
          </p>
        </div>`;
      },
      error: () => html``,
    });
  }

  static styles = [
    ...BaseLitElement.styles,
    css`
      :host {
        display: block;
        width: 100%;
      }
    `,
  ];
}
