import { css, html } from "lit";
import { Task } from "@lit/task";
import { customElement, property } from "lit/decorators.js";
import { hc } from "hono/client";
import { ProductDetailsRoute } from "../../routes/_apis/products";
import { TrackingElement } from "./TrackingElement";
import { cacheFetch } from "../../libs/client/fetch";
import { CustomNotFoundError } from "../../libs/errors";

@customElement("survaq-sold-out-sale")
class SurvaqSoldOutSale extends TrackingElement {
  @property() productId?: string;
  @property({ type: Boolean }) noScheduleTable: boolean = false;
  @property({ type: Boolean }) scrollToBottom: boolean = false;
  @property({ type: Boolean }) delayedOnly: boolean = false;
  @property({ type: Boolean }) hideWhenNoDelay: boolean = false;

  private _productTask = new Task(this, {
    task: async ([productId], { signal }) => {
      if (!productId) throw new Error("productIdを指定してください。");

      const baseUrl = new URL("https://api.survaq.com/products/");
      if (import.meta.env.DEV) {
        baseUrl.protocol = "http:";
        baseUrl.hostname = "localhost";
        baseUrl.port = "8000";
      }
      const client = hc<ProductDetailsRoute>(baseUrl.toString(), {
        init: { signal },
        fetch: cacheFetch,
      });

      let retryCount = 0;
      while (retryCount++ < 3) {
        try {
          const res = await client[":id"].$get({
            param: { id: productId },
          });

          if (res.status === 404) throw new CustomNotFoundError("商品が存在しません。");
          if (!res.ok) throw new Error(`想定しないエラーが発生しました。status: ${res.status}`);

          return res.json();
        } catch (e) {
          console.warn(e);
          if (e instanceof CustomNotFoundError) throw e;
          // 3秒後にリトライ
          await new Promise((r) => setTimeout(r, 3000));
        }
      }
      throw new Error("想定しないエラーが発生しました。");
    },
    args: () => [this.productId] as const,
    onComplete: () => {
      // 初期状態でトラッキンを停止しているので、データ取得後に再開
      this.resumeTracking();
    },
  });

  connectedCallback() {
    super.connectedCallback();
    // 初期状態ではトラッキングを一時停止(レンダリングが成功してから再開)
    this.pauseTracking();
  }

  static styles = [
    ...TrackingElement.styles,
    css`
      :host {
        display: block;
        width: 100%;
      }
    `,
  ];

  render() {
    return html`
      ${this.renderTopMarker()}
      ${this._productTask.render({
        pending: () => html``,
        complete: (product) => {
          return html`<div
            class="pt-10 pb-7 px-5 border-8 border-[#cd5954] text-center flex flex-col items-center text-slate-700"
          >
            <h2 class="font-black text-3xl mb-2.5">
              品切れ
              <span class="tracking-[-0.15em] -translate-y-5 inline-block mr-1">
                <span class="rotate-[-25deg] inline-block origin-[center_bottom_0]">お</span>
                <span class="-translate-y-1.5 inline-block origin-[center_bottom_0]">詫</span>
                <span class="rotate-[25deg] inline-block origin-[center_bottom_0]">び</span>
              </span>
              セール
            </h2>
            <div
              class="relative w-[300px] mb-10 bg-neutral-400 text-white p-1.5 font-bold flex justify-between tracking-widest"
            >
              <span class="w-[115px] tracking-tight">次回配送予定</span>
              <img
                class="w-16 h-[100px] absolute left-[122px] top-[-38px]"
                loading="lazy"
                src="https://cdn.shopify.com/s/files/1/0562/8844/4621/files/img_female.png?v=1652071518"
                alt=""
              />
              <span class="w-[115px] tracking-tight">
                ${product.schedule?.text.replace(/(\d{4}|年)/g, "") ?? "-"}
                ${product.schedule?.text.endsWith("旬") ? "頃" : ""}
              </span>
            </div>
            <p class="text-base mb-7 font-black leading-loose md:text-lg md:leading-loose">
              想定以上のご注文をいただいており、<br />現在品切れ中です。<br />お詫びの気持ちを込めて品切れ期間中は<br />特別価格で販売いたします。
            </p>
            ${this.scrollToBottom
              ? html`
                  <p class="text-base font-black leading-loose md:text-lg md:leading-loose">
                    詳しくはページ下部をチェック
                    <img
                      class="w-[47px] h-[44px] mx-auto"
                      alt=""
                      src="https://cdn.shopify.com/s/files/1/0562/8844/4621/files/ico_scroll.png?v=1652071518"
                    />
                  </p>
                `
              : html`<p class="text-base mb-7 font-black leading-loose md:text-lg md:leading-loose">
                  ※次回分が入荷次第、<br />本セールは終了します。
                </p>`}
            ${!this.noScheduleTable
              ? html`<survaq-delivery-schedule
                  productId=${this.productId}
                  ?delayedOnly=${this.delayedOnly}
                  ?hideWhenNoDelay=${this.hideWhenNoDelay}
                />`
              : ""}
          </div>`;
        },
        error: () => html``,
      })}
      ${this.renderBottomMarker()}
    `;
  }
}
