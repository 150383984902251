import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { TrackingElement } from "./TrackingElement";

@customElement("survaq-banner-modal")
class SurvaqBannerModal extends TrackingElement {
  @state() private isOpen = false;

  render() {
    return html`
      ${this.renderTopMarker()}
      <button @click="${this.openModal}">
        <slot name="button"></slot>
      </button>
      ${this.renderBottomMarker()}
      <div
        class="fixed top-0 left-0 w-full h-full bg-black/60 py-10 px-2.5 overflow-auto flex justify-center z-10
               ${this.isOpen ? "block" : "hidden"}"
        ?hidden="${!this.isOpen}"
        @click="${this.closeModal}"
      >
        <div class="relative inline-block max-w-[600px] w-[90%] h-fit">
          <div class="sticky top-0 flex justify-end h-0">
            <button
              class="relative flex items-center justify-center text-center -top-5 -right-5 size-10 text-white bg-black rounded-full text-xl"
              aria-label="閉じる"
              @click="${this.closeModal}"
            >
              ×
            </button>
          </div>
          <div class="space-y-1 flex justify-center flex-col" @click="${this.stopPropagation}">
            <slot name="content"></slot>
            <button
              @click="${this.closeModal}"
              class="bg-transparent font-semibold w-full text-white underline"
            >
              とじる
            </button>
          </div>
        </div>
      </div>
    `;
  }

  private openModal() {
    this.isOpen = true;
    document.body.style.overflow = "hidden";
  }

  private closeModal() {
    this.isOpen = false;
    document.body.style.overflow = "";
  }

  private stopPropagation(e: Event) {
    e.stopPropagation();
  }
}
