// client-only
declare global {
  interface Window {
    _survaqFetchCache: Map<string, Promise<Response>>;
  }
}

window._survaqFetchCache = window._survaqFetchCache || new Map<string, Promise<Response>>();

export const cacheFetch = async (input: RequestInfo | URL, init?: RequestInit) => {
  const method = init?.method?.toUpperCase() || "GET";

  if (method !== "GET") {
    return fetch(input, init);
  }

  const key = input.toString();

  if (window._survaqFetchCache.has(key)) {
    return window._survaqFetchCache.get(key)!.then((response) => response.clone());
  } else {
    const fetchPromise = fetch(input, init);
    window._survaqFetchCache.set(key, fetchPromise);

    return fetchPromise.then((response) => {
      if (!response.ok) window._survaqFetchCache.delete(key);
      return response.clone();
    });
  }
};
